import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
// import { getImage } from "gatsby-plugin-image";
import photoHome from "../../static/img/foto_home.jpg";
import photoHomeHeader from "../../static/img/foto_home_header.jpg";
import {getUrlFromImage} from "../components/Gallery";
import arrow from "../../static/img/arrow.svg";
import Layout from "../components/Layout";
import { Link } from 'gatsby'
// import Features from "../components/Features";
// import BlogRoll from "../components/BlogRoll";
// import FullWidthImage from "../components/FullWidthImage";

// eslint-disable-next-line
export const IndexPageTemplate = class extends React.Component {
  // const heroImage = getImage(image) || image;
  componentDidMount() {
    let root = document.documentElement;
    root.style.setProperty('--tp-navbar-color', '#fff');
  }
  render() {
    return (
      <div>
        <section className="header">
          <div className="header-image" style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)), url(${getUrlFromImage(this.props.titleImage)})`}}></div>
          <div className="container">
            <h1 className="title">UW TUIN<br/><span>ONZE PASSIE</span></h1>
            <div className="row mb-5">
              <div className="col-lg-8 pt-3">
                <p>Bij ons wordt uw tuin met passie en ambitie verzorgd, van het uittekenen van een plan tot het daadwerkelijke aanleggen. <br/>
    Klantenservice staat bij ons hoog in het vaandel.</p>
                <div className="tp-header-links">
                  <Link to="/#aanbod" className="tp-btn-white" title="Aanbod">Aanbod</Link>
                  <Link to="/#over" className="tp-link-white" title="Over">Over ons</Link>
                </div>
                
              </div>
            </div>
          </div>
        </section>
        <section className="over content pt-6" id="over">
        <div className="container">
  
            <div className="row mb-5">
              <div className="col-lg-4">
                <div className="over-column">
                  <h1 className="title">
                    OVER <br/>
                    <span>PRINSEN</span> <br/>
                    TUINPROJECTEN <br/>
                  </h1>
                  <div className="foto-prinsen-tp" style={{background: `url(${getUrlFromImage(this.props.overImage)}) top center / cover no-repeat`}}></div>
                </div>
  
              </div>
              <div className="col-lg-8 ps-5">
                <p>
                  Tuinprojecten Prinsen is ondertussen al sinds 2021 actief voor allerhande tuinwerken. Dit na een opleiding Agro-en biotechnologie te hebben afgerond te Geel, waardoor we een aanspreekpunt zijn voor het totaal gamma van uw tuin.
                </p>
                <p>Wij bieden als tuinbouwbedrijf drie grote elementen aan. Tuinarchitectuur, van een simpele schets op papier tot een totaal uitgewerkt 3D-ontwerp. Tuinaanleg, van beplanting tot alle soorten verhardingen en afsluitingen. Ten derde doen wij ook in allerhande soorten zwembaden.</p>
  
  <p>Na een project te hebben afgewerkt kan u ook nog altijd rekenen op ons over hoe en wat juist nog te doen met uw tuin. Dit van snoeien van bomen en hagen tot het mooi groen houden van uw gazon.</p>
  
  <p>Stuur ons gerust een mailtje en wij beantwoorden deze zo spoedig mogelijk.</p>
  <p>Offerte en afspraken zijn gratis en vrijblijvend.</p>
              </div>
            </div>
          </div>
  
        </section>
        <section className="aanbod pb-6" id="aanbod">
          <div className="container">
            <h2>WIJ MAKEN UW<br/><span>DROOM</span>TUIN</h2>
            <div className="row">
              <div className="aanbod-elements">
                <Link to="/tuinarchitectuur" title="Tuinarchitectuur" className="enlarge" style={{background: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2)), url(${getUrlFromImage(this.props.tuinArchitectuurImage)}) top center / cover no-repeat`}}>
                    <div className="link-btn">
                      <img src={arrow} alt="arrow" width="16" height="16" />
                    </div>
                    <div className="aanbod-text">TUINARCHITECTUUR</div>
                </Link>
                <Link to="/tuinaanleg" title="Tuinaanleg" className="enlarge" style={{background: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2)), url(${getUrlFromImage(this.props.tuinAanlegImage)}) top center / cover no-repeat`}}>
  
                    <div className="link-btn">
                      <img src={arrow} alt="arrow" width="16" height="16" />
                    </div>
                    <div className="aanbod-text">TUINAANLEG</div>
  
                </Link>
                <Link to="/zwembadvijver" title="Zwembaden vijvers" className="enlarge" style={{background: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2)), url(${getUrlFromImage(this.props.tuinVijverImage)}) top center / cover no-repeat`}}>
                  <div className="link-btn">
                      <img src={arrow} alt="arrow" width="16" height="16" />
                    </div>
                    <div className="aanbod-text">
                    ZWEMVIJVERS<br/>ZWEMBADEN<br/>ECOZWEMBADEN
                    </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="projecten-link">
          <div className="projecten-image-background"></div>
          <Link to="/projecten" title="Projecten">
            <div className="container projecten-image" style={{background: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2)), url(${getUrlFromImage(this.props.footerBannerImage)}) top center / cover no-repeat`}}>
              <div className="link-btn">
                <img src={arrow} alt="arrow" width="16" height="16" />
              </div>
              <div className="link-text">
              BEKIJK ONZE <br/>PROJECTEN
              </div>
            </div>
          </Link>
        </section>
      </div>
    )
  }
  
}

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        titleImage={frontmatter.titleImage}
        overImage={frontmatter.overImage}
        tuinArchitectuurImage={frontmatter.tuinArchitectuurImage}
        tuinAanlegImage={frontmatter.tuinAanlegImage}
        tuinVijverImage={frontmatter.tuinVijverImage}
        footerBannerImage={frontmatter.footerBannerImage}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        titleImage {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 60) {
              ...GatsbyImageSharpFluid
            }
            original {
              width
              height
            }
          }
        }
        overImage {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 60) {
              ...GatsbyImageSharpFluid
            }
            original {
              width
              height
            }
          }
        }
        tuinArchitectuurImage {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 60) {
              ...GatsbyImageSharpFluid
            }
            original {
              width
              height
            }
          }
        }
        tuinAanlegImage {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 60) {
              ...GatsbyImageSharpFluid
            }
            original {
              width
              height
            }
          }
        }
        tuinVijverImage {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 60) {
              ...GatsbyImageSharpFluid
            }
            original {
              width
              height
            }
          }
        }
        footerBannerImage {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 60) {
              ...GatsbyImageSharpFluid
            }
            original {
              width
              height
            }
          }
        }
      }
    }
  }
`;
